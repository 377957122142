.reset-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.reset-password-container {
  max-width: 400px;
  margin-top: -100px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  background-color: #f9f9f9;

  h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  input[type="email"] {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    background-color: #dddddd;
    color: #c70f15;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.7;
  }

  p.error {
    color: #ff0000;
    margin-top: 10px;
  }
}
