.form-container {
  @media screen and (max-width: 360px) {
    button {
      width: 222px;
    }
    width: 200px;
  }

  .form-field-login {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 320px;
    @media screen and (max-width: 360px) {
      width: 100%;
    }
  }
}
