.result-search-container {
  display: flex;
  gap: 10px;
}
.occurences_column {
  text-align: center;
}
.english_column {
  text-align: center;
}
.btn-dictionary {
  background-color: #ddd;
  color: #c70f15;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

.add-col {
  flex: 1;
}

.table-col {
  flex: 9;
}
.search-add-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.checkbox-container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  margin-right: 16px;
}
.btn-add-word {
  width: 80px;
  margin-right: 0px;
}
.icon_loading_container {
  background-size: cover; /* Đảm bảo hình ảnh bao phủ toàn bộ phần nền */
  background-repeat: no-repeat; /* Ngăn lặp lại hình ảnh nếu nó nhỏ hơn kích thước nền */
  width: 100%; /* Chiều rộng 100% để nền mờ bao phủ toàn bộ màn hình */
  height: 100vh; /* Chiều cao 100vh để nền mờ bao phủ toàn bộ màn hình */
  position: absolute; /* Cố định nền mờ để nó không cuốn lên khi cuộn trang */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  z-index: 1;
}
.bg-login-page {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.rotate-image {
  width: 220px;
  height: 220px;
  background-image: url("../assets/sns.png");
  background-size: cover;
  animation: rotate 4s linear infinite;
  position: absolute;
  opacity: 0.8;
  z-index: 2;
}
.rotate-image {
  width: 220px;
  height: 220px;
  background-image: url("../assets/sns.png");
  background-size: cover;
  animation: rotate 4s linear infinite;
  position: absolute;
  opacity: 0.8;
  z-index: 2;
}
.sns-logo {
  width: 100%;
  height: 100px;
  background-position: center center;
  object-fit: cover;
  background-image: url("../assets/sns.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .result-search-container {
    display: flex;
    gap: 10px;
  }

  /* CSS cho responsive media trên tablet */
}

@media (max-width: 768px) {
  // CSS mobile
  .result-search-container {
    flex-direction: column;
    margin: 0;
  }
  .search_container {
    margin: 0;
  }
  td {
    width: 500px;
  }
  .add-col {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    margin-top: 15px;
  }

  .btn-add-word {
    width: 100px;
  }

  .ant-table-content,
  .ant-table-wrapper {
    padding: 0;
    overflow-x: auto;
  }
  col {
    text-align: center;
    min-width: 150px !important;
  }
}
.title-dictionary {
  margin: 30px 0 0 0;
  font-family: "Raleway", sans-serif;
}
