.search_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .search-form-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .btn-dictionary-dropdown {
    outline: none;
    border-width: 1px;
    border-radius: 6px 0px 0px 6px;
    background-color: #d9d9d9;
    color: rgb(199, 15, 21);
    padding-left: 10px;
    span {
      color: rgb(199, 15, 21) !important;
    }
  }
  .input-search-dictionary:focus {
    outline: none;
  }
  .input-search-dictionary {
    height: 36.5px;
    border-style: solid !important;
    border-width: 1px;
    width: 365px;
    border-color: #d9d9d9;
  }
  .search-dictionary-form {
    height: 50px;
  }
  .btn-search {
    width: 50px;
    border-radius: 0px 6px 6px 0px !important;
    color: rgb(199, 15, 21);
    background-color: #d9d9d9;
  }
}
.table-search-result {
  .ant-table-content {
    margin: 16px 5% 0px 5%;
    table {
      border: solid 0.1px rgb(221, 221, 221);
      td {
        border: solid 0.1px rgb(221, 221, 221);
      }
      th {
        border: solid 0.1px rgb(221, 221, 221);
        text-align: center;
      }
      tr {
        border: solid 0.1px rgb(221, 221, 221) !important;
      }
      thead > tr:first-child > *:first-child {
        border-start-start-radius: 0px !important;
      }
      thead > tr:first-child > *:last-child {
        border-start-end-radius: 0px !important;
      }
    }
    table-layout: auto;
  }
  .ant-table-wrapper table {
    border-radius: 0 !important;
  }

  .ant-pagination {
    display: none !important;
  }
  .ant-table-container {
    border: none !important;
  }
}
@media (max-width: 768px) {
  .ant-table-content {
    margin: 16px 30px 0px 30px;
  }
  .input-search-dictionary {
    width: 200px !important;
  }
}
