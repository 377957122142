.confirmation-container {
  .warning_modal {
    p {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}
h4 {
  margin: 3px 0 3px 0;
}
.ant-modal-body {
  gap: 0px !important;
}
