.register-container {
  margin-top: -100px;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 300px; /* Set the width to a fixed value */
      padding: 10px;
      color: #c70f15;
      border-radius: 5px;
      border: solid 1px rgba(0, 0, 0, 0.304);
      height: 20px;
    }
    @media screen and (max-width: 400px) {
      input {
        width: 200px; /* Set the width to a fixed value */
        padding: 10px;
        background-color: #ddd;
        color: #c70f15;
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.304);
        height: 22px;
      }
    }
  }

  label {
    margin-bottom: 4px;
  }

  .btn-register {
    color: #c70f15;
    background-color: #ddd;
    width: 80px;
    height: 35px;
  }
}

::-ms-reveal {
  display: none;
}
