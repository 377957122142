.ant-alert .ant-alert-action {
  display: "flex;";
}

.warning_modal {
  .btn {
    width: 144.5px;
    border: none;
    color: white;
    height: 28px;
    font-size: 13px;
  }
  .btn-cancel {
    width: 144.5px;
    border: none;
    color: #878787;
    outline: none;
    background: none;
  }
  .btn-delete {
    display: flex;

    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--red, #ff5656);
  }
  .btn-cancel:hover {
    opacity: 0.8;
  }
  .btn-delete:hover {
    opacity: 0.8;
    color: none !important;
  }

  .warning-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-action-edit {
    width: 15.71px;
    height: 17.89px;
  }
  .warning-delete .ant-alert-description {
    margin-top: 5px;
    text-align: center;
  }

  .btn-action-edit:hover {
    width: 15.71px;
    height: 17.89px;
    cursor: pointer;
    opacity: 0.8;
  }

  .btn-action-delete {
    width: 15.71px;
    height: 17.89px;
  }

  .btn-action-delete:hover {
    width: 15.71px;
    height: 17.89px;
    cursor: pointer;
    opacity: 0.8;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
  .ant-modal-footer {
    margin-top: 22px;
  }
  .ant-modal-content {
    width: 329px !important;
    height: 200px;
    padding: 16px;
  }
}
