.manage-user {
  button {
    color: white;
    width: 50px;
    height: 27px;
    margin: 0 3px;
    font-size: 12px;
  }
  button:hover {
    opacity: 0.8;
  }
  .item-user-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .button-wrapper {
    position: relative;
    display: inline-block;

    cursor: pointer;
  }

  .container-btn-manage-edited {
    white-space: nowrap;
    position: absolute;
    right: 0;
    color: #c70f15;
    transition: 0.2s ease;
    visibility: hidden;
    width: 70px;
    background-color: #d9d9d9;
  }
  .button-manage-editor {
    white-space: nowrap;
    position: absolute;
    right: 0;
    color: #c70f15;
    transition: 0.2s ease;
    visibility: hidden;
    width: 70px;
    background-color: #d9d9d9;
    white-space: pre-wrap;
    height: 30px;
  }

  .button-wrapper:hover .button-manage-edited-column {
    transform: translate(-62px, -45px);
    visibility: visible;
  }

  .button-wrapper:hover .button-manage-allow-add {
    transform: translate(12px, -45px);
    visibility: visible;
  }
  .container-btn-manage-edited-disable {
    display: none;
  }
  .button-wrapper:hover .button-manage-edior {
    transform: translate(85px, -45px);
    visibility: visible;
  }
}

.button-wrapper:hover + .ant-modal {
  filter: blur(10px) !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.btn-admin-dictionary-dropdown {
  display: flex;
  border-radius: 6px;
  background-color: #dddddd;
  padding-left: 5px;
  gap: 0 !important;
  color: #c70f15;
  display: flex;
  gap: 4px;
  height: 40px;
  @media screen and (max-width: 400px) {
    height: 30px !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d9d9d9 !important;
  border: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: red; /* Thay đổi màu sắc ở đây */
}
