.form-group {
  margin-bottom: 16px;
  label {
    display: block;
    margin-bottom: 8px;
    font-family: "Poppins", sans-serif;
  }
  #value2 {
    width: 356px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    height: 160px;
    resize: none;
    @media screen and (max-width: 500px) {
      width: 250px;
    }
  }

  #value1 {
    color: rgba(135, 135, 135, 1);
    width: 356px;
    padding: 11px 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    @media screen and (max-width: 500px) {
      width: 250px;
    }
  }
  #value1:focus {
    color: black;
  }

  #value1::placeholder {
    color: rgba(135, 135, 135, 1);
  }
  #value2::placeholder {
    color: rgba(135, 135, 135, 1);
  }
  .error-message {
    color: red;
    font-size: 8px;
    margin-top: 8px;
  }
}
textarea::placeholder {
  font-size: 16px;
}
input::placeholder {
  font-size: 16px;
}
.btn {
  height: 40px;
  border-radius: 8px;
}
.btn-cancel {
  color: grey;
  border: none;
  background: none;
  font-size: 16px;
  font-family: sans-serif;
}

.btn-save {
  background-color: #c1c1c1;
  color: #6d080c;
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  border: none;
  outline: none;
  align-items: center;
  gap: 8;
  flex: 1 0 0;
  border-radius: 12px;
  font-size: 16px;
}
.edit_modal {
  width: auto !important;
}
.btn-save:hover {
  opacity: 0.8;
}
.ant-modal {
  .ant-modal-body {
    max-height: 60vh; /* Đặt kích thước tối đa của modal */
    overflow-y: auto; /* Tạo thanh cuộn khi cần */
  }
  .ant-modal-content {
    border-radius: 16px;
    height: 100%;
    padding: 16px !important;
  }
  .ant-modal-footer {
    display: flex;
  }
}
.edit-icon-container {
  .svg-inline--fa {
    height: 41px;
    width: 41px;
  }
}
textarea {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
